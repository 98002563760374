import { IconButton, Dialog, DialogTitle, DialogContent, CircularProgress, Button, Divider, styled, Typography } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef, useState } from "react";
import InitPage from "./pages/InitPage";
import SelectServicePage from "./pages/SelectServicePage";
import styles from './OrderDialog.module.css';
import SelectMasterPage from "./pages/SelectMasterPage";
import SeletTimePage from "./pages/SelectTimePage";
import { useMutation, useQuery, useQueryClient } from "react-query";
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import buildPages from "./pages/Pages";
import dayjs from "dayjs";
import { logAndReset } from "../../../utils/requestUtils";
import { useSelector } from "react-redux";
import ROLES from "../../../constants/roles";
import UserSelector2 from "../../widgets/UserSelector2";
import { useTranslation } from "react-i18next";
import { trackBookingPage } from "../../../ga";
import SERVER_CODES from "../../../net/codes";

const ONE_MINUTE = 60 * 1000;

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));


const OrderDialog = ({ onClose, open, targetService }) => {
  const navigator = {
    openSelectServicePage: () => {
      console.log("open", pages.selectService.key, ', current page: ', page.key, ", pageRef:", pageRef.current.key);
      navigationStack.current.push(pageRef.current)
      setPage(pages.selectService)
    },
    openSelectMasterPage: () => {
      console.log("open", pages.selectMaster.key, ', current page: ', page.key, ", pageRef:", pageRef.current.key);
      navigationStack.current.push(pageRef.current)
      setPage(pages.selectMaster)
    },
    openSelectTimePage: () => {
      console.log("open", pages.selectTime.key, ', current page: ', page.key, ", pageRef:", pageRef.current.key);
      navigationStack.current.push(pageRef.current)
      setPage(pages.selectTime)
    },
    openInitPage: (currentPage) => {
      console.log("open", pages.init.key, ', current page: ', page.key, ", pageRef:", pageRef.current.key);
      navigationStack.current.push(pageRef.current)
      setErrorCode(null);
      setPage(pages.init);
    },
    openRegisterPage: () => {
      console.log("open", pages.init.key, ', current page: ', page.key, ", pageRef:", pageRef.current.key);
      navigationStack.current.push(pageRef.current)
      setPage(pages.register)
    },
    openRestorePage: () => {
      console.log("open", pages.init.key, ', current page: ', page.key, ", pageRef:", pageRef.current.key);
      navigationStack.current.push(pageRef.current)
      setPage(pages.restore)
    },
    goBack: () => {
      const previousPage = navigationStack.current.pop()
      console.log("go to previousPage:", previousPage.key, "stack:", navigationStack.current.map(v => v.key));
      setPage(previousPage);
    },
    goBackToTime: () => {
      setErrorCode(null);
      navigationStack.current.push(pageRef.current)
      setPage(pages.selectTime)
    },
    goBackToSummury: () => {
      var previousPage = navigationStack.current.pop()
      console.log("goBackToSummury:", previousPage);
      while (previousPage.key !== pages.summary.key) {
        console.log("goBackToSummury2:", previousPage, pages.summary);
        previousPage = navigationStack.current.pop()
      }
      console.log("go to previousPage:", previousPage.key, "stack:", navigationStack.current.map(v => v.key));
      setPage(previousPage);
    }
  }
  const { t } = useTranslation();
  const pages = buildPages(navigator, t);
  const user = useSelector((state) => state?.user);
  const [page, setPage] = useState(targetService ? pages.selectMaster : pages.init);
  const [selectedServiceId, setSelectedServiceId] = useState(targetService ? targetService.id : null);
  const [selectedMasterId, setSelectedMasterId] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [records, setRecords] = useState([]);
  const [targetUser, setTargetUser] = useState(null);
  const [note, setNote] = useState(null);
  const [policyError, setPolicyError] = useState({});
  const [errorCode, setErrorCode] = useState(null);
  const [errorTime, setErrorTime] = useState(null);
  const policyRef = useRef({});
  const navigationStack = useRef([]);
  const pageRef = useRef(pages.init);
  if (pageRef.current != page) {
    pageRef.current = page;
  }
  useEffect(() => {
    if (targetService) {
      navigationStack.current.push(pages.init);
      navigationStack.current.push(pages.selectService);
      trackBookingPage("open dialog", targetService.id);
    } else {
      trackBookingPage("open dialog", pages.init.key);
    }
  }, [])

  console.log("render current page:", page.key, "stack:", navigationStack.current.map(v => v.key));

  const bookItems = useQuery(QUERY_KEYS.BOOK_ITEMS, REQUESTS.getBookItems(), { refetchOnMount: true });
  const doRecordMutation = useMutation(REQUESTS.doRecord());
  const queryClient = useQueryClient();

  const doRecord = () => {
    let keys = Array.from( Object.keys(policyRef.current) );
    const badKeys = keys.filter(key => policyRef.current[key].checked === false && policyRef.current[key].required === true)
    badKeys.forEach(key => {
      setPolicyError(prev => ({ ...prev, [key]: true}))
    })
    if (badKeys.length > 0) {
      return;
    }

    const data = {
      records,
      clientId: targetUser ? targetUser.id : user.id,
      hasConsent: policyRef.current,
      note: note ? note : undefined
    }
    doRecordMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log(`schedule was removed successfully!`)
        queryClient.invalidateQueries(QUERY_KEYS.BOOK_ITEMS);
        
        setPage(pages.final)
      },
      onError: (error) => {
        if (error.response?.data?.error?.code === SERVER_CODES.NOT_FOUND) {
          setErrorCode(SERVER_CODES.NOT_FOUND)
          const record = records.find(v => error.response?.data?.error?.message.indexOf("" + v.time) != -1)
          setErrorTime(record.time)
        }
        logAndReset(doRecordMutation);
      }
    });
  }

  const handleClose = () => {
    onClose();
  };

  const handleBack = () => {
    switch (page.key) {
      case pages.selectService.key:
        setSelectedServiceId(null);
        break;
      case pages.selectMaster.key:
        setSelectedMasterId(null);
        break;
      case pages.selectTime.key:
        setSelectedTime(null);
        setErrorTime(null);
        break;
    }
    const previousPage = navigationStack.current.pop()
    console.log("previousPage:", previousPage.key, "stack:", navigationStack.current.map(v => v.key));
    setPage(previousPage);
  };

  const handleForward = () => {
    console.error("handleForward", records);
    console.error("handleForward page", page.key, "errorTime", errorTime, "page.key === pages.selectTime: ", page.key === pages.selectTime, "errorTime !== null:", errorTime !== null);
    if (page.key === pages.selectTime.key && errorTime !== null) {
      const fixedRecords = records.map(record => record.time === errorTime ? { ...record, time: selectedTime } : record);
      console.error("fixed:", fixedRecords);
      setRecords(fixedRecords);
      setErrorTime(null);
      setSelectedTime(null);
      const previousPage = navigationStack.current.pop()
      console.log("previousPage:", previousPage.key, "stack:", navigationStack.current.map(v => v.key));
      setPage(previousPage);
      return
    }
    const nextPage = getNextPage();
    if (nextPage.key === pages.summary.key) {
      navigationStack.current = [];
      setRecords(prev => [...prev, { id: Date.now(), serviceId: selectedServiceId, masterId: selectedMasterId, time: selectedTime }]);
      setSelectedServiceId(null);
      setSelectedMasterId(null);
      setSelectedTime(null);
    } else {
      navigationStack.current.push(page)
    }
    
    console.log("next Page:", nextPage.key, "stack:", navigationStack.current.map(v => v.key));
    if (nextPage.key === pages.final.key) {
      doRecord()
    } else {
      setPage(nextPage);
    }
  };

  const onDeleteRecordItem = (record) => {
    setRecords(records.filter(item => item !== record))
  }

  const buildContent = (data) => {
    var selected, onClick, build;
    switch (page.key) {
      case pages.selectService.key:
        selected = selectedServiceId;
        onClick = (service) => selectedServiceId === service.id ? setSelectedServiceId(null) : setSelectedServiceId(service.id)
        build = buildAvailableServices;
        break;
      case pages.selectMaster.key:
        selected = selectedMasterId;
        onClick = (master) => selectedMasterId === master.id ? setSelectedMasterId(null) : setSelectedMasterId(master.id)
        build = buildAvailableMasters;
        break;
      case pages.selectTime.key:
        selected = selectedTime;
        onClick = (time) => selectedTime === time ? setSelectedTime(null) : setSelectedTime(time)
        build = buildAvailableTime;
        break;
      case pages.summary.key:
        return page.content({ ...data, records, policyRef, policyError, onDelete: onDeleteRecordItem })
      case pages.final.key:
        return page.content({ ...data, records, targetUser })
    }

    return page.content({ ...data, selected, onClick, build, page })
  }

  const getNextPage = () => {
    if ((selectedServiceId && selectedMasterId && selectedTime && page.key !== pages.summary.key) || errorTime !== null) {
      return pages.summary;
    }

    switch (page.key) {
      case pages.selectService.key:
        if (!selectedMasterId) {
          return pages.selectMaster;
        } else {
          return pages.selectTime;
        }
        break;
      case pages.selectMaster.key:
        if (!selectedServiceId) {
          return pages.selectService;
        } else {
          return pages.selectTime;
        }
        break;
      case pages.selectTime.key:
        if (!selectedServiceId) {
          return pages.selectService;
        } else {
          return pages.selectMaster;
        }
        break;
      case pages.summary.key:
        if (user) {
          return pages.final;
        } else {
          return pages.login;
        }
        
    }
  }

  const isChecked = () => {
    switch (page.key) {
      case pages.selectService.key:
        return selectedServiceId;
      case pages.selectMaster.key:
        return selectedMasterId;
      case pages.selectTime.key:
        return selectedTime;
      case pages.summary.key:
        return true;
    }
    return false;
  }

  const buildTimes = (ranges, busySlots, minTime, timeFrame, startAvailableTime) => {
    const times = new Set();
    ranges.sort((one, two) => one.startTime - two.startTime).forEach(range => {
      const currentBusySlots = busySlots.filter(v => v.masterId === range.masterId)
      for (let start = range.startTime; start < range.endTime; start += timeFrame) {
        if (start + minTime <= range.endTime) {
          var isBusy = false;
          currentBusySlots.forEach(item => {
            // if current busy slot item has the same master
            const sameMaster = item.masterId === range.masterId
            // start time of pointer is in the busy range
            const isStartInBusyRange = start >= item.startTime && start < item.endTime;
            // end time of pointer is in the busy range
            const isEndInBusyRange = start + minTime > item.startTime && start + minTime <= item.endTime;
            // pointer time wider than busy slot
            const hasBusyInMiddle = start <= item.startTime && start + minTime >= item.endTime
            if (sameMaster && (isStartInBusyRange || isEndInBusyRange || hasBusyInMiddle)) {
              isBusy = true;
            }
          })
          if (!isBusy && start >= startAvailableTime) {
            times.add(start)
          } 
        }
        
      }
    })
    return Array.from(times);
  }
  
  const buildAvailableTime = () => {
    const data = bookItems.data
    const services = data.services.filter(service => (!selectedServiceId || service.id === selectedServiceId));
    const masters = data.masters.filter(master => (!selectedMasterId || master.id === selectedMasterId));
    var minTime = null;
    data.masterServices.filter(item => masters.find(master => master.id === item.masterId) && services.find(service => service.id === item.serviceId)).forEach(item => {
      if (item.timeRequirements * ONE_MINUTE < minTime || minTime === null) {
        minTime = item.timeRequirements * ONE_MINUTE;
      }
    });
    if (minTime) {
      const ranges = data.masterSlots.filter(item => masters.find(master => master.id === item.masterId))
      
      const currentBusySlots = records.map(record => ({
        masterId: record.masterId, 
        startTime: record.time, 
        endTime: record.time + data.masterServices.find(v => v.serviceId === record.serviceId && v.masterId === record.masterId).timeRequirements * ONE_MINUTE
      }))
      return buildTimes(ranges, [...data.busySlots, ...currentBusySlots], minTime, data.timeFrame, data.startActiveTime)
    } else {
      return []
    }
  }

  
  const masterCanFitInTime = (time, timeRequirements, ranges, busySlots) => {
    const range = ranges.find(range => range.startTime <= time && time + timeRequirements <= range.endTime)
    return range && !busySlots.find(busySlot => 
      (busySlot.endTime > time && busySlot.endTime <= time + timeRequirements) || 
      (busySlot.startTime >= time && busySlot.startTime < time + timeRequirements) || 
      (busySlot.startTime <= time && busySlot.endTime >= time + timeRequirements)
    )
  }

  const masterHasFreeSlotFor = (timeFrame, timeRequirements, ranges, busySlots) => {
    return ranges.find(range => {
      for (let start = range.startTime; start + timeRequirements <= range.endTime; start += timeFrame) {
        if (masterCanFitInTime(start, timeRequirements, [range], busySlots)) {
          return true;
        }
      }
      return false;
    })
  }
  
  const buildAvailableServices = () => {
    const data = bookItems.data
    const masters = data.masters.filter(master => (!selectedMasterId || master.id === selectedMasterId));
    const availableMasterServices = data.masterServices.filter(item => masters.find(v => v.id === item.masterId))
    
    const services = data.services.filter(service => {
      // has master that provides such service 
      const masterService = availableMasterServices.find(item => service.id === item.serviceId);
      if (!masterService) {
        return false;
      }
      if (selectedTime) {
        // for target service exist master that can fit in time
        const results = availableMasterServices.filter(v => service.id === v.serviceId).map(availableMasterService => {
          const ranges = data.masterSlots.filter(item => item.masterId === availableMasterService.masterId);
          const busySlots = buildBusySlots(data.busySlots, availableMasterService);
          return masterCanFitInTime(selectedTime, availableMasterService.timeRequirements * ONE_MINUTE, ranges, busySlots);
        })
        return results.length > 0 && results.find(item => item === true)

        // // and he can fit in time
        // const ranges = data.masterSlots.filter(item => item.masterId === masterService.masterId);
        // const busySlots = buildBusySlots(data.busySlots, masterService); //data.busySlots.filter(item => item.masterId === masterService.masterId);
        // return masterCanFitInTime(selectedTime, masterService.timeRequirements * ONE_MINUTE, ranges, busySlots);
      } else if (selectedMasterId) {
        // check if any time exist
          const masterService = availableMasterServices.find(v => v.masterId === selectedMasterId)
          const ranges = data.masterSlots.filter(item => item.masterId === masterService.masterId);
          const busySlots = buildBusySlots(data.busySlots, masterService); //data.busySlots.filter(item => item.masterId === masterService.masterId);
          return masterHasFreeSlotFor(data.timeFrame, masterService.timeRequirements * ONE_MINUTE, ranges, busySlots);
      }
      return true;
    });
    return services;
  }

  const buildAvailableMasters = () => {
    const data = bookItems.data
    const services = data.services.filter(service => (!selectedServiceId || service.id === selectedServiceId));
    const availableMasterServices = data.masterServices.filter(item => services.find(v => v.id === item.serviceId));
    
    if (selectedTime) {
      const masters = data.masters.filter(master => {
        const masterService = availableMasterServices.find(v => v.masterId === master.id)
        
        if (masterService) {
          const ranges = data.masterSlots.filter(item => item.masterId === masterService.masterId);
          const busySlots = buildBusySlots(data.busySlots, masterService); //data.busySlots.filter(item => item.masterId === masterService.masterId);
          return masterCanFitInTime(selectedTime, masterService.timeRequirements * ONE_MINUTE, ranges, busySlots);
        }

        return masterService
      });
      return masters;
    } else {
      if (selectedServiceId) {
        const masters = data.masters.filter(master => {
          const masterService = availableMasterServices.find(v => v.masterId === master.id)
          
          if (masterService) {
            const ranges = data.masterSlots.filter(item => item.masterId === masterService.masterId);
            const busySlots = buildBusySlots(data.busySlots, masterService); //data.busySlots.filter(item => item.masterId === masterService.masterId);
            console.log("masterHasFreeSlotFor:", masterService.timeRequirements * ONE_MINUTE, ranges, busySlots);
            return masterHasFreeSlotFor(data.timeFrame, masterService.timeRequirements * ONE_MINUTE, ranges, busySlots);
          }
  
          return masterService
        });
        return masters;
      }
    }
    return data.masters.filter(master => availableMasterServices.find(v => v.masterId === master.id))
  }

  const buildBusySlots = (busySlots, masterService) => {
    const targetBusySlots = busySlots.filter(item => item.masterId === masterService.masterId);
    if (records.find(item => item.masterId === masterService.masterId)) {
      const current = records
        .filter(v => v.masterId === masterService.masterId)
        .map(item => ({ masterId: item.masterId, startTime: item.time, endTime: item.time + masterService.timeRequirements * ONE_MINUTE }))
      return [...targetBusySlots, ...current]
    } else {
      return targetBusySlots;
    }
  }

  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <div className={styles.title}>
          {
            (page.hasBackNavigation || (page.key === pages.init.key && records.length > 0)) && 
              <IconButton
                onClick={handleBack}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
          }
          <DialogTitle sx={{ m: 0, paddingLeft: 1, paddingRight: 1 }} >
            { page.title }
          </DialogTitle>
        </div>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ paddingTop: 1 }}>
          {
            user && user.role !== ROLES.USER && page.key !== pages.final.key && <UserSelector2 user={targetUser} onChange={targetUser => setTargetUser(targetUser)} note={note} onNoteChange={note => setNote(note)} onClose={handleClose} />
          }
          { bookItems.isLoading ? <CircularProgress /> : buildContent(bookItems.data) }
          { page.key == pages.summary.key && getNextPage().key === pages.login.key && <Typography>{ t('loginToBookRecord') }</Typography> }
          { errorCode === null && page.hasForwardNavigation && <Button disabled={!isChecked()} variant="contained" sx={{ marginTop: 2, width: '100%', textTransform: 'none' }} onClick={handleForward}>{ getNextPage().nextText }</Button> }
          { errorCode === SERVER_CODES.NOT_FOUND && <><Typography>{ t('timeIsNotAvailable') }</Typography> <Button variant="outlined" onClick={() => navigator.goBackToTime()}>{ t('orderDialogTimesNextText') }</Button></> }
        </DialogContent>
      </BlurryDialog>
    </>
  );
}

export default OrderDialog;